import sanitizeHtml from "isomorphic-html-sanitize";
import { Component } from "react";
import styled from "styled-components";
import intlService from "../../services/intl";
import { addDisclaimerLink, parseMarkdown } from "../../utils/markdown";
import {
  Color,
  Font,
  media,
  Opacity,
  rem,
  responsive,
} from "../../utils/style";

const AccordionWrapper = styled.div.attrs((p) => ({
  id: `accordion-item-${p.index}`,
}))`
  border-top: 1px solid ${Color.fadedGrey};

  &:last-child {
    border-bottom: 1px solid ${Color.fadedGrey};
  }
`;

const AccordionHeader = styled.button.attrs((p) => ({
  id: `accordion-item-${p.index}_header`,
  className: "product-questions__accordion__header",
}))`
  position: relative;
  background: transparent;
  border: none;
  width: 100%;
  padding: 0;
`;

const HeaderText = styled.h3.attrs((p) => ({
  id: `accordion-item-${p.index}_header_title`,
}))`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${(p) => (p.small ? rem(16) : rem(18))};
  font-weight: 500;
  text-align: left;
  letter-spacing: 0;
  line-height: ${rem(28)};
  padding: ${(p) =>
    p.small
      ? p.open
        ? "16px 0 8px"
        : "16px 0"
      : p.open
      ? "24px 0 16px"
      : "24px 0"};
  margin: 0;
  max-width: calc(100% - 18px - 8px);

  ${media.mobile`
    font-size: ${(p) => (p.xsmall ? rem(12) : rem(16))};
  `}

  ${responsive.md`
    max-width: calc(100% - 18px - 16px);
  `};

  &:hover {
    cursor: pointer;
  }
`;

const AccordionBody = styled.div.attrs((p) => ({
  id: `accordion-item-${p.index}_body`,
}))`
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  padding-bottom: ${rem(24)};
  display: none;

  ${Font.dutch} &.open {
    display: block;
  }

  a,
  a[data-modal="supplement-facts"] {
    display: inline-block;
    border-bottom: 1px solid ${Color.ritualBlue};
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: ${Opacity.light};
    }

    &#footnote-ref {
      border: none;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

const AccordionBodyList = styled.ul.attrs((p) => ({
  id: `accordion-item-${p.index}_body`,
}))`
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  margin: unset;
  padding-inline-start: ${rem(16)};
  padding-top: ${rem(12)};
  display: none;

  ${Font.dutch} &.open {
    display: block;
  }

  a,
  a[data-modal="supplement-facts"] {
    display: inline-block;
    border-bottom: 1px solid ${Color.ritualBlue};
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: ${Opacity.light};
    }

    &#footnote-ref {
      border: none;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

const AccordionBodyListItem = styled.li`
  ${Font.circular}
  font-size: ${rem(12)};
  line-height: ${rem(20)};
  font-weight: 300;
  text-align: left;

  &::marker {
    color: ${Color.ritualYellow};
  }

  ${responsive.sm`
    font-size: ${rem(16)};
    line-height: ${rem(28)};

    & > div {
      color: #62719f;
    }
  `};

  & > div {
    color: #62719f;
  }
`;

const Toggle = styled.div.attrs((p) => ({
  id: `accordion-item-${p.index}_header_toggle`,
}))`
  position: absolute;
  right: 0;
  top: calc(50% - 5px);
  width: 18px;
  height: 12px;
  transform-origin: center;
  cursor: pointer;

  &.open {
    transform: rotateZ(180deg);
  }

  &:before {
    position: absolute;
    left: 0;
    content: "";
    height: 2px;
    width: 12px;
    background-color: ${Color.ritualBlue};
    transform: translateY(5px) rotate(45deg);
    transform-origin: center;
  }

  &:after {
    position: absolute;
    left: 0;
    content: "";
    height: 2px;
    width: 12px;
    background-color: ${Color.ritualBlue};
    transform: translateX(7.5px) translateY(5px) rotate(-45deg);
    transform-origin: center;
  }
`;

export default class AccordionItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openIndex: null,
    };
  }

  handleHeaderClick() {
    this.props.headerClick();
  }

  render() {
    const { item, open, small, index } = this.props;
    let disclaimerLink, accordionList, accordionHeader;
    const accordionBodyArray = Array.isArray(item.body);

    // accordion is used for bundle quiz results
    if (accordionBodyArray) {
      accordionList = item.body;
      accordionHeader = intlService.t(item.header);
    } else {
      const sanitizedContent = sanitizeHtml(item.body, { allowedTags: [] });
      const markdownConvertedContent = parseMarkdown(sanitizedContent);
      disclaimerLink = addDisclaimerLink(markdownConvertedContent);
      accordionHeader = item.header;
    }

    return (
      <AccordionWrapper index={index}>
        <AccordionHeader
          index={index}
          onClick={this.handleHeaderClick.bind(this)}
          aria-expanded={open}
          aria-controls={`accordion-item-${index}_header`}
        >
          <Toggle index={index} className={open ? "open" : ""} />
          <HeaderText
            index={index}
            small={small}
            open={open}
            xsmall={accordionBodyArray}
          >
            {accordionHeader}
          </HeaderText>
        </AccordionHeader>
        {disclaimerLink ? (
          <AccordionBody
            index={index}
            role="region"
            aria-labelledby={`accordion-item-${index}_body`}
            className={open ? "open" : ""}
            dangerouslySetInnerHTML={{
              __html: disclaimerLink,
            }}
          />
        ) : (
          <AccordionBodyList
            index={index}
            role="region"
            aria-labelledby={`accordion-item-${index}_body_list`}
            className={open ? "open" : ""}
          >
            {accordionList &&
              accordionList.map((rec, i) => {
                return (
                  <AccordionBodyListItem
                    key={i}
                    dangerouslySetInnerHTML={{ __html: intlService.t(rec) }}
                  />
                );
              })}
          </AccordionBodyList>
        )}
      </AccordionWrapper>
    );
  }
}
