import { marked } from "marked";
import intl from "../services/intl";
import { FootnoteId } from "../data/constants/html";

export function localizeMarkdownLinks(data) {
  return data.replace(
    /\[([^\]]*)\]\(([^)]*)\)/g,
    (_, g1, g2) => `[${g1}](${intl.localizePath(g2)})`,
  );
}

export function localizeHtmlLinks(data) {
  return data.replace(
    /href="(.*?)"/g,
    (_, g1) => `href="${intl.localizePath(g1)}"`,
  );
}

export function formatLinksForNewTab(data) {
  return data.replace(
    /href="(.*?)"/g,
    (_, g1) => `href="${g1}" target="_blank" rel="noopener"`,
  );
}

export function parseMarkdown(content) {
  const localized = localizeMarkdownLinks(content);
  return marked.parseInline(localized);
}

export function processHtml(content) {
  return localizeHtmlLinks(content);
}

export function addDisclaimerLink(content) {
  return content.replace(
    /(\*+)/g,
    `<a aria-describedby="disclaimer-footnote-label" href="#${FootnoteId}" id="footnote-ref">*</a>`,
  );
}
