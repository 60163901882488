import { Component } from "react";
import styled from "styled-components";

// Utils
import { graphql } from "gatsby";
import queryString from "query-string";
import $ from "jquery";
import { Color, rem, responsive, Opacity } from "../utils/style";
import { BannerType } from "../utils/bannerType";
import metrics from "../utils/metrics";

// Components
import Container from "../components/Container";
import Row from "../components/Row";
import PageSEO from "../components/seo/Page";
import VerifyButton from "../components/discount/VerifyButton";
import CircleNew from "../components/home/Circle.New";
import Accordion from "../components/product/Accordion";
import PageHero from "../components/PageHero";
import HeroContent from "../components/discount/HeroContent";
import RitualButton from "../components/global/RitualButton";

// Actions
import { connect } from "react-redux";
import { addPendingCode } from "../store/pending-code/actions";
import { updateBannerType } from "../store/banners/actions";

// Styled Elements
const PageContent = styled.div`
  position: relative;
  overflow: hidden;

  padding-top: 40px;
  padding-bottom: 80px;

  ${responsive.md`
    padding-top: 120px;
    padding-bottom: 120px;
  `};
`;

const PageColumn = styled.div.attrs({
  className: "col-12 col-md-10 offset-md-1",
})`
  position: relative;
  z-index: 10;
`;

const AccordionColumn = styled.div.attrs({
  className: "col-12 col-sm-8 offset-sm-2 p-sm-0",
})`
  position: relative;
  z-index: 10;
`;

const BodyCopy = styled.p`
  text-align: center !important;
  font-size: ${rem(26)};
  line-height: ${rem(36)};
  letter-spacing: -0.3px;
  font-weight: 500;
  margin-bottom: 40px;

  ${responsive.md`
    font-size: ${rem(40)};
    line-height: ${rem(54)};
    letter-spacing: -1.17px;
  `};
`;

const ButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
  display: block;
`;

const FAQTitle = styled.h2`
  text-align: center !important;
  font-size: ${rem(26)};
  line-height: ${rem(36)};
  letter-spacing: -0.3px;
  font-weight: 500;
  margin-top: 80px;
  margin-bottom: 40px;

  ${responsive.md`
    font-size: ${rem(48)};
    line-height: ${rem(56)};
    letter-spacing: -1.4px;
    margin-top: 120px;
    margin-bottom: 56px;
  `};
`;

const AlternativeCopy = styled.div`
  p {
    margin: 0;
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    font-weight: 500;
    color: ${Color.ritualBlue};
    text-align: center;

    ${responsive.md`
      font-size: ${rem(22)};
      line-height: ${rem(32)};
      letter-spacing: -0.2px;
    `}

    a {
      text-decoration: none;

      &:hover {
        opacity: ${Opacity.light};
      }
    }
  }
`;

const DiscountPage = class DiscountPageTemplate extends Component {
  constructor(props) {
    super(props);
    let { slug, pageTitle, pageDescription, socialImage } =
      props.data.contentfulDiscountPage;

    const {
      location: { hash },
    } = props;

    // ID.me returns the attributes as a hash (#) instead of query params (?)
    // Remove the # at the start
    const queryParams = hash && hash.length ? hash.substring(1) : "";
    // Parse in to an object
    const parsedQueryParams = queryString.parse(queryParams);

    this.state = {
      queryParams: parsedQueryParams,
      verified: false,
      seo: {
        pagePath: `${slug}`,
        title: pageTitle,
        description: pageDescription,
        image: {
          url: socialImage.file.url,
          width: socialImage.file.details.image.width,
          height: socialImage.file.details.image.height,
        },
      },
    };
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Discount Page",
    });

    const {
      queryParams: { access_token },
    } = this.state;

    if (access_token) {
      this.getCoupon(access_token);
    }
  }

  getCoupon(access_token) {
    const { scope } = this.props.data.contentfulDiscountPage;
    const { dispatchAddPendingCode } = this.props;

    const apiUrl = process.env.GATSBY_RITUAL_API_URL + "/api/v1/idme/generate";

    $.ajax({
      url: apiUrl,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        access_token: access_token,
        scope: scope,
      },
    }).then((res) => {
      const {
        attributes: { code },
      } = res.data;

      if (code) {
        dispatchAddPendingCode(code);

        this.setState({
          verified: true,
        });

        metrics.track("ID_Me Verified", {});

        this.props.dispatchUpdateBannerType(BannerType.IDME);
      }
    });
  }

  renderCta(isPrimary) {
    const { slug, typeformSurveyUrl, scope, heroCtaText } =
      this.props.data.contentfulDiscountPage;
    const { verified } = this.state;
    return scope ? (
      <VerifyButton
        verified={verified}
        scope={scope}
        slug={slug}
        className={isPrimary ? "primary-cta" : ""}
        copy={heroCtaText}
        eventDetails={{ title: "ID_Me Verification" }}
      />
    ) : (
      <RitualButton
        className={isPrimary ? "primary-cta" : ""}
        href={typeformSurveyUrl}
        target="_self"
      >
        {heroCtaText}
      </RitualButton>
    );
  }

  render() {
    const {
      bodyCopy,
      heroBackgroundColor,
      heroImageDesktop,
      heroImageMobile,
      heroTitle,
      heroSubheader,
      faq,
      faqTitle,
      scope,
      typeformSurveyUrl,
      alternativeEmailCopy,
    } = this.props.data.contentfulDiscountPage;

    const accordionItems = JSON.parse(
      faq.childMarkdownRemark.rawMarkdownBody,
    ).map((q) => {
      return {
        header: q.question,
        body: q.answer,
      };
    });

    const displayButton = scope || typeformSurveyUrl;

    return (
      <>
        <PageSEO {...this.state.seo} />
        <PageHero
          id="discount"
          backgroundColor={heroBackgroundColor}
          imageMobile={heroImageMobile}
          imageDesktop={heroImageDesktop}
        >
          <HeroContent heroTitle={heroTitle} heroSubheader={heroSubheader}>
            {displayButton && this.renderCta(true)}
          </HeroContent>
        </PageHero>
        <PageContent>
          <Container>
            <Row>
              <PageColumn>
                <BodyCopy>{bodyCopy}</BodyCopy>
                {displayButton && (
                  <ButtonWrapper>{this.renderCta()}</ButtonWrapper>
                )}
                {alternativeEmailCopy && (
                  <AlternativeCopy
                    dangerouslySetInnerHTML={{
                      __html: alternativeEmailCopy.childMarkdownRemark.html,
                    }}
                  />
                )}
                <FAQTitle>{faqTitle}</FAQTitle>
              </PageColumn>
              <AccordionColumn>
                <Accordion
                  noGridAligment
                  items={accordionItems}
                  eventDetails={{ location: "ID_Me FAQ" }}
                />
              </AccordionColumn>
            </Row>
          </Container>
          <CircleNew className="bottom left" />
        </PageContent>
      </>
    );
  }
};

export default connect(null, {
  dispatchAddPendingCode: addPendingCode,
  dispatchUpdateBannerType: updateBannerType,
})(DiscountPage);

export const query = graphql`
  query DiscountQuery($locale: String!, $slug: String!) {
    contentfulDiscountPage(node_locale: { eq: $locale }, slug: { eq: $slug }) {
      slug
      heroTitle {
        childMarkdownRemark {
          html
        }
      }
      heroSubheader
      heroImageDesktop {
        file {
          url
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 1920
          quality: 90
        )
      }
      heroImageMobile {
        file {
          url
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 912
          quality: 90
        )
      }
      heroBackgroundColor
      heroCtaText
      scope
      typeformSurveyUrl
      bodyCopy
      faqTitle
      faq {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      pageTitle
      pageDescription
      socialImage {
        file {
          details {
            image {
              width
              height
            }
          }
          url
        }
      }
    }
  }
`;
