import styled from "styled-components";

// Utils
import { Font, Color, rem, media, responsive } from "../../utils/style";

// Styled Elements
const HeroContent = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const HeroTitle = styled.h1`
  ${Font.circular};
  text-align: center;
  font-size: ${rem(30)};
  line-height: ${rem(36)};
  font-weight: 500;
  letter-spacing: -0.4px;
  text-align: center !important;
  margin: 0 auto 8px;

  ${responsive.sm`
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    letter-spacing: -1.4px;
    margin-bottom: 16px;
    max-width: 400px;
  `};

  ${responsive.md`
    max-width: 450px;
  `};

  ${responsive.lg`
    font-size: ${rem(66)};
    line-height: ${rem(72)};
    letter-spacing: -2px;
    margin-bottom: 24px;
    max-width: 550px;
  `};
`;

const HeroSubheader = styled.p`
  ${Font.dutch};
  color: ${Color.ritualBlue};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: normal;
  margin-bottom: 24px;
  text-align: center !important;

  ${responsive.md`
    font-size: ${rem(20)};
    line-height: ${rem(30)};
    max-width: 453px;
    margin: 0 auto 32px;
  `};

  ${responsive.lg`
    margin-bottom: 40px;
  `};
`;

export default function DiscountHeroContent({
  children,
  heroTitle,
  heroSubheader,
}) {
  const cleanHtml = heroTitle.childMarkdownRemark.html
    .replace("<p>", "")
    .replace("</p>", "");
  return (
    <HeroContent>
      <HeroTitle
        dangerouslySetInnerHTML={{
          __html: cleanHtml,
        }}
      />
      <HeroSubheader>{heroSubheader}</HeroSubheader>
      {children}
    </HeroContent>
  );
}
